@font-face {
  font-family: 'Azo Sans';
  src: url('assets/fonts/Azo Sans.otf') format('truetype');
}
@font-face {
  font-family: 'ChronicleDisplay-Bold';
  src: url('assets/fonts/ChronicleDisplay-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'ChronicleDisplay-BoldItalic';
  src: url('assets/fonts/ChronicleDisplay-BoldItalic.otf') format('truetype');
}
@font-face {
  font-family: 'ChronicleDisplay-Light';
  src: url('assets/fonts/ChronicleDisplay-Light.otf') format('truetype');
}
@font-face {
  font-family: 'eyeCatching';
  src: url('assets/fonts/eyeCatching.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaCondensed';
  src: url('assets/fonts/Helvetica-Condensed.otf') format('truetype');
}
@font-face {
  font-family: 'SFUIText-Regular';
  src: url('assets/fonts/SFUIText-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SFProDisplay-Semibold';
  src: url('assets/fonts/SFProDisplay-Semibold.otf') format('truetype');
}
@font-face {
  font-family: 'Verveine';
  src: url('assets/fonts/Verveine.ttf') format('truetype');
}
@font-face {
  font-family: 'NeueMontreal-Medium';
  src: url('assets/fonts/NeueMontreal-Medium.ttf') format('truetype')
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
